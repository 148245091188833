export function MicroPhonePermissions(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      xmlSpace="preserve"
      aria-labelledby="titleId descId"
      {...props}
    >
      <title id="titleId">
        Dialog indicating microphone permissions required
      </title>
      <desc id="descId">
        You need to grant microphone permissions to use this feature. Click the
        padlock icon in the address bar and grant microphone permissions.
      </desc>
      <path fill="none" d="M0 0h1080v1080H0z" />
      <g clipPath="url(#a)">
        <path
          d="M120.5 942.36V210.83a72.326 72.326 0 0121.178-51.144A72.319 72.319 0 01192.82 138.5h767.26"
          fill="none"
        />
        <path
          d="M120.5 942.36V210.83a72.326 72.326 0 0121.178-51.144A72.319 72.319 0 01192.82 138.5h767.26"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M960.08 256.93h-110a23.43 23.43 0 01-23.43-23.43v-71.56a23.428 23.428 0 00-6.859-16.574 23.435 23.435 0 00-16.571-6.866H536.29a23.435 23.435 0 00-16.571 6.866 23.43 23.43 0 00-6.859 16.574v71.56a23.432 23.432 0 01-23.43 23.43H120.5v137.62h839.58"
          fill="none"
        />
        <path
          d="M960.08 256.93h-110a23.43 23.43 0 01-23.43-23.43v-71.56a23.428 23.428 0 00-6.859-16.574 23.435 23.435 0 00-16.571-6.866H536.29a23.435 23.435 0 00-16.571 6.866 23.43 23.43 0 00-6.859 16.574v71.56a23.432 23.432 0 01-23.43 23.43H120.5v137.62h839.58"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M210.81 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86z"
          fill="#FF8669"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M274.32 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86z"
          fill="#FFB929"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M337.84 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86z"
          fill="#6DD58C"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52z"
          fill="none"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M460.21 353.89a38.369 38.369 0 01-64.265-31.835 38.364 38.364 0 0128.046-33.32 38.368 38.368 0 0141.119 14.355"
          fill="none"
        />
        <path
          d="M460.21 353.89a38.369 38.369 0 01-64.265-31.835 38.364 38.364 0 0128.046-33.32 38.368 38.368 0 0141.119 14.355"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M470.57 298.9l2.52 16.75-15.95-5.72 13.43-11.03z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M236.18 329.44h-63.69m29.13 29.13l-29.13-29.13 29.13-29.12m72.7 29.12h63.69m-29.13 29.13l29.13-29.13-29.13-29.12"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M679.55 562.59H514.16c-10.598 0-19.19 8.589-19.19 19.185 0 10.596 8.592 19.185 19.19 19.185h165.39c10.598 0 19.19-8.589 19.19-19.185 0-10.596-8.592-19.185-19.19-19.185zm0 245.6H514.16c-10.598 0-19.19 8.589-19.19 19.185 0 10.596 8.592 19.185 19.19 19.185h165.39c10.598 0 19.19-8.589 19.19-19.185 0-10.596-8.592-19.185-19.19-19.185z"
          fill="none"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M294.11 741.88c0-19.507-15.813-35.32-35.32-35.32-19.507 0-35.32 15.813-35.32 35.32v83.58c0 19.507 15.813 35.32 35.32 35.32 19.507 0 35.32-15.813 35.32-35.32v-83.58z"
          fill="#94BD21"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M313.51 826.95a54.72 54.72 0 11-109.44 0m54.72 54.72v31.84m-35.32 0h70.64"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M370.59 520.1l-42.9 34.55v-33.56a16.689 16.689 0 00-4.914-11.803 16.682 16.682 0 00-11.826-4.857H199.06a16.699 16.699 0 00-11.843 4.849 16.682 16.682 0 00-4.927 11.811v121.34a16.693 16.693 0 0016.75 16.62h111.91a16.682 16.682 0 0016.74-16.62v-28.85l43.34 31.66a11.923 11.923 0 0012.42 1.068 11.925 11.925 0 006.58-10.588V529.28a11.924 11.924 0 00-6.83-10.71 11.916 11.916 0 00-12.61 1.53v0z"
          fill="#94BD21"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M625.31 322.08h-27.15a7.88 7.88 0 00-7.88 7.88v17.19a7.88 7.88 0 007.88 7.88h27.15a7.88 7.88 0 007.88-7.88v-17.19a7.88 7.88 0 00-7.88-7.88z"
          fill="none"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M595.13 322.09v-12.86a16.764 16.764 0 014.895-11.877 16.757 16.757 0 0111.865-4.923 16.76 16.76 0 0116.76 16.76v12.86"
          fill="none"
        />
        <path
          d="M595.13 322.09v-12.86a16.764 16.764 0 014.895-11.877 16.757 16.757 0 0111.865-4.923v0a16.76 16.76 0 0116.76 16.76v12.86"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52z"
          fill="none"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M521.29 325.74a149.004 149.004 0 0039.1 100.87h220.75a149.512 149.512 0 001.15-200.47h-223a149 149 0 00-38 99.6v0z"
          fill="none"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M690.9 274.8h369.18V376H690.9a50.612 50.612 0 01-50.61-50.61v0a50.612 50.612 0 0150.61-50.59v0z"
          fill="#F2F2F2"
          stroke="#F2F2F2"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M562.74 326.15a79.61 79.61 0 0023.314 56.286 79.614 79.614 0 0056.286 23.314h154.75a149.584 149.584 0 00.52-159.2H642.34a79.6 79.6 0 00-79.6 79.6v0z"
          fill="#F2F2F2"
          stroke="#F2F2F2"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g>
          <path
            d="M696.46 320.91h-42.71c-6.843 0-12.39 5.547-12.39 12.39v27.04c0 6.843 5.547 12.39 12.39 12.39h42.71c6.843 0 12.39-5.547 12.39-12.39V333.3c0-6.843-5.547-12.39-12.39-12.39z"
            fill="#073348"
            stroke="#073348"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M648.99 320.92V300.7a26.357 26.357 0 017.721-18.639 26.357 26.357 0 0118.639-7.721v0a26.357 26.357 0 0118.639 7.721 26.357 26.357 0 017.721 18.639v20.22"
            stroke="#073348"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52z"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M514.8 625.12c23.936 0 43.34-19.404 43.34-43.34 0-23.936-19.404-43.34-43.34-43.34-23.936 0-43.34 19.404-43.34 43.34 0 23.936 19.404 43.34 43.34 43.34zm0 245.6c23.936 0 43.34-19.404 43.34-43.34 0-23.936-19.404-43.34-43.34-43.34-23.936 0-43.34 19.404-43.34 43.34 0 23.936 19.404 43.34 43.34 43.34z"
          fill="#073348"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(119 137)"
            d="M0 0h842.58v806.85H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
