import * as LucideIcons from "lucide-react";

import {
  AppleLogo,
  FacebookLogo,
  GithubLogo,
  GoogleLogo,
  LinkedInLogo,
  MicroPhonePermissions,
  PaypalLogo,
  RadixLogo,
  TailwindLogo,
  TwilioLogo,
  WattLogo,
  tRPCLogo
} from "./svgs";

export type Icon = LucideIcons.LucideIcon | React.FC;

const convertToCamelCase = (str: string): string =>
  str.charAt(0).toLowerCase() + str.slice(1);

type CamelCase<S extends string> =
  S extends `${infer P1}_${infer P2}${infer P3}`
    ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
    : S extends `${infer First}${infer Rest}`
      ? `${Lowercase<First>}${Rest}`
      : Lowercase<S>;

const lucideIconsCamelCase = Object.entries(LucideIcons).reduce<
  Record<CamelCase<LucideIconName>, LucideIcons.LucideIcon>
>(
  (acc, [iconName, iconComponent]) => {
    const camelCaseName = convertToCamelCase(
      iconName
    ) as CamelCase<LucideIconName>;
    acc[camelCaseName] = iconComponent as LucideIcons.LucideIcon;
    return acc;
  },
  {} as Record<CamelCase<LucideIconName>, LucideIcons.LucideIcon>
);

export type LucideIconName = keyof typeof LucideIcons;

export type RenamedIcons =
  | "close"
  | "spinner"
  | "post"
  | "page"
  | "media"
  | "billing"
  | "ellipsis"
  | "add"
  | "addCircle"
  | "warning"
  | "help"
  | "charity"
  | "soletrader"
  | "copyDone"
  | "electricity"
  | "electric"
  | "gas"
  | "sun"
  | "solar"
  | "battery"
  | "internet"
  | "water"
  | "energyAudit"
  | "finance"
  | "businessInsurance"
  | "ceased"
  | "active"
  | "todo"
  | "inProgress"
  | "dashboard"
  | "interactions"
  | "companies"
  | "quotes"
  | "creditCheck"
  | "contracts"
  | "loa"
  | "statistics"
  | "calls"
  | "notes"
  | "dial"
  | "people"
  | "sites"
  | "filePlus"
  | "success"
  | "cancel"
  | "low"
  | "medium"
  | "high"
  | "disolved"
  | "disable"
  | "postcode"
  | "openMenu"
  | "compose"
  | "favorite"
  | "edit"
  | "system"
  | "call"
  | "deal"
  | "measure"
  | "goldenRecord";

export type CustomIconName =
  | "gitHub"
  | "radix"
  | "tailwind"
  | "google"
  | "apple"
  | "paypal"
  | "twitter"
  | "facebook"
  | "linkedin"
  | "twilio"
  | "tRPC"
  | "wattLogo"
  | "microphonePermissions";

const namedContextIcons = {
  // Lucide icons with different names for better context
  close: LucideIcons.X,
  spinner: LucideIcons.Loader2,
  post: LucideIcons.FileText,
  page: LucideIcons.File,
  media: LucideIcons.Image,
  billing: LucideIcons.CreditCard,
  ellipsis: LucideIcons.MoreVertical,
  add: LucideIcons.Plus,
  addCircle: LucideIcons.PlusCircle,
  warning: LucideIcons.AlertTriangle,
  help: LucideIcons.HelpCircle,
  charity: LucideIcons.HelpingHand,
  soletrader: LucideIcons.UserRound,
  copyDone: LucideIcons.ClipboardCheck,
  electricity: LucideIcons.Lightbulb,
  electric: LucideIcons.Plug,
  gas: LucideIcons.Flame,
  sun: LucideIcons.SunMedium,
  solar: LucideIcons.Sun,
  battery: LucideIcons.BatteryCharging,
  internet: LucideIcons.Wifi,
  water: LucideIcons.Droplet,
  energyAudit: LucideIcons.ActivitySquare,
  finance: LucideIcons.Coins,
  businessInsurance: LucideIcons.Building2,
  ceased: LucideIcons.ShieldX,
  active: LucideIcons.ShieldCheck,
  todo: LucideIcons.Circle,
  inProgress: LucideIcons.ArrowUpCircle,
  dashboard: LucideIcons.LayoutDashboard,
  interactions: LucideIcons.MessagesSquare,
  companies: LucideIcons.Building2,
  quotes: LucideIcons.TextQuote,
  creditCheck: LucideIcons.CreditCard,
  contracts: LucideIcons.FileSignature,
  loa: LucideIcons.Scale,
  statistics: LucideIcons.BarChartBig,
  calls: LucideIcons.Phone,
  notes: LucideIcons.FileText,
  dial: LucideIcons.Grip,
  people: LucideIcons.Users2,
  sites: LucideIcons.MapPin,
  filePlus: LucideIcons.FilePlus2,
  success: LucideIcons.CheckCircle2,
  cancel: LucideIcons.XCircle,
  low: LucideIcons.ArrowDownToLine,
  medium: LucideIcons.ArrowRightToLine,
  high: LucideIcons.ArrowUpToLine,
  disolved: LucideIcons.Ban,
  disable: LucideIcons.Ban,
  postcode: LucideIcons.MapPin,
  openMenu: LucideIcons.MoreHorizontal,
  compose: LucideIcons.MailPlus,
  mailOpen: LucideIcons.MailOpen,
  favorite: LucideIcons.Star,
  edit: LucideIcons.Pen,
  system: LucideIcons.BellRing,
  call: LucideIcons.Phone,
  deal: LucideIcons.Handshake,
  measure: LucideIcons.TrendingUp,
  goldenRecord: LucideIcons.Apple,
  signalZero: LucideIcons.SignalZero,
  signalMedium: LucideIcons.SignalMedium,
  signalLow: LucideIcons.SignalLow,
  signalHigh: LucideIcons.SignalHigh,
  signal: LucideIcons.Signal,
  timer: LucideIcons.Timer,
  zap: LucideIcons.Zap
};

export const Icons = {
  ...lucideIconsCamelCase,
  ...namedContextIcons,

  gitHub: GithubLogo,
  radix: RadixLogo,
  tailwind: TailwindLogo,
  google: GoogleLogo,
  apple: AppleLogo,
  paypal: PaypalLogo,
  facebook: FacebookLogo,
  linkedin: LinkedInLogo,
  twilio: TwilioLogo,
  tRPC: tRPCLogo,
  wattLogo: WattLogo,

  // Custom SVG Images
  microphonePermissions: MicroPhonePermissions
};
